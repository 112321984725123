(function ($) {
    'use strict';

    if ($.fn.footerReveal) {
        $('footer').footerReveal({
            shadow: true,
            shadowOpacity: 0.3,
            zIndex: -101
        });
    }

    if ($.fn.scrollUp) {
        $.scrollUp({
            scrollSpeed: 500,
            scrollText: '<i class="fas fa-arrow-circle-up"></i>'
        });
    }

    // if ($.fn.counterUp) {
    //     $('.counter').counterUp({
    //         delay: 10,
    //         time: 2000
    //     });
    // }

    var topoffset = 48; //variable for menu height

    //Use smooth scrolling when clicking on navigation
    $('.navbar-nav a').click(function () {
        if (location.pathname.replace(/^\//, '') ===
            this.pathname.replace(/^\//, '') &&
            location.hostname === this.hostname) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
            if (target.length) {
                $('html,body').animate({
                    scrollTop: target.offset().top - topoffset
                }, 500);
                return false;
            } //target.length
        } //click function
    }); //smooth scrolling

    $('a[href="#"]').click(function ($) {
        $.preventDefault()
    });

    $('.navbar-collapse a').on('click', function () {
        if ($('.navbar-collapse').hasClass('show')) {
            $('.navbar-toggler').click();
        }
    });

    var $window = $(window);

    $window.on('scroll', function () {
        if ($window.scrollTop() > 48) {
            $('.header_area').addClass('sticky slideInDown');
        } else {
            $('.header_area').removeClass('sticky slideInDown');
        }
    });

    $window.on('load', function () {
        $('#preloader').fadeOut('slow', function () {
            $(this).remove();
        });
    });

})(jQuery);